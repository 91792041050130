// adds class to body so we can enable/disable focus rings
// https://github.com/ten1seven/what-input
import "what-input";

// // delay lazy image loading
// window.lazySizesConfig = window.lazySizesConfig || {};
// // page is optimized for fast onload event
// window.lazySizesConfig.loadMode = 1;

import "lazysizes/plugins/parent-fit/ls.parent-fit";
import "lazysizes/plugins/object-fit/ls.object-fit";
import 'lazysizes/plugins/native-loading/ls.native-loading';
// import "lazysizes/plugins/aspectratio/ls.aspectratio";
// import "lazysizes/plugins/attrchange/ls.attrchange";
import "lazysizes";

import 'alpinejs';
import Swup from 'swup';
import SwupScrollPlugin from '@swup/scroll-plugin';
import SwupScriptsPlugin from '@swup/scripts-plugin';
import SwupHeadPlugin from '@swup/head-plugin';
// import SwupDebugPlugin from '@swup/debug-plugin';
import Cookies from 'js-cookie';

window.cookies = Cookies;

const swup = new Swup({
  animationSelector: '[class*="swup-transition-"]',
  containers: ["#swup, #top-banner"],
  plugins: [
    new SwupScrollPlugin(),
    new SwupScriptsPlugin({
      head: false,
      body: false
    }),
    new SwupHeadPlugin({
      persistAssets: true
    })
    // new SwupDebugPlugin()
  ]
});
